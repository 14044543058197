@font-face {
  font-family: "SF_ProDisplay_Heavy";
  src: local("SF_ProDisplay_Heavy"),
   url("./assets/fonts/FontsFree-Net-SFProDisplay-Heavy-1.ttf") format("truetype");
}
@font-face {
  font-family: "SF_ProDisplay_Bold";
  src: local("SF_ProDisplay_Bold"),
   url("./assets/fonts/FontsFree-Net-SFProDisplay-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "SF_ProDisplay_SemiBold";
  src: local("SF_ProDisplay_SemiBold"),
   url("./assets/fonts/FontsFree-Net-SFProDisplay-Semibold.ttf") format("truetype");
}
@font-face {
  font-family: "SF_ProDisplay_Medium";
  src: local("SF_ProDisplay_Medium"),
   url("./assets/fonts/FontsFree-Net-SFProDisplay-Medium-1.ttf") format("truetype");
}
@font-face {
  font-family: "SF_ProDisplay_Regular";
  src: local("SF_ProDisplay_Regular"),
   url("./assets/fonts/FontsFree-Net-SFProDisplay-Regular-1.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
