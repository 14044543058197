.pc_mySpecification_container {
    flex: 1;
    padding-top: 65px;
    padding-left: 20px;
    padding-right: 20px;
}

.pc_textArea {
    width: 100%;
    border-radius: 15px;
    padding: 15px;
    font-size: 16px;
    font-family: 'SF_ProDisplay_Regular';
    outline: none;
}

.pc_textArea::placeholder {
    color: #AAAAAA;
}
