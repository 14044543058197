.pc_desktosectionContainer {
    display: flex;
    justify-content: center;
    width: 100%;
}

.pc_desktosectionSubContainer {
    width: 100%;
}

@media (min-width: 760px) { 

    .pc_desktosectionSubContainer {
        width: 50%;
    }
    
}