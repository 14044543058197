.pc_screen_wrapper_container {
    height: 100svh;
    width: 100%;
    background-image: url('../../assets/bg.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    background-attachment: fixed;

    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.pc_blur_bg {
    background-image: url('../../assets/blurBg.png');
}

.pc_screen_wrapper_header_container {
    width: 100%;
    top: 45px;
    position: absolute;
    display: flex;
    align-items: baseline;
    justify-content: center;
}
.pc_screen_wrapper_header_subcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.pc_arrow_left {
    position: absolute;
    left: 26px;
}

.pc_screen_wraper_title_header {
    margin: 0%;
    font-size: 18px;
    color: #fff;
    font-family: 'SF_ProDisplay_Heavy';
}

.pc_screen_wraper_subTitle_header {
    color: #fff;
    margin: 0%;
    font-family: 'SF_ProDisplay_Heavy';
    font-size: 25px;
    line-height: 23px;
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
}

.pc_screen_wraper_optionalTxt_header {
    margin: 0%;
    color: #fff;
    font-size: 15px;
    margin-top: 5px;
    font-family: 'SF_ProDisplay_Bold';
}

.pc_screen_wrapper_children_container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pc_screen_wrapper_footer {
    width: 100%;
    position: absolute;
    bottom: 35px;
}

@media (min-width: 760px) { 

    .pc_screen_wrapper_container {
        background-image: url('../../assets/bgDesktop.png');
    }
    
    .pc_blur_bg {
        background-image: url('../../assets/blurDesktopBg.png');
    }

    .pc_screen_wrapper_footer {
        width: 30%;
        margin-left: 35%;
    }

    .pc_screen_wrapper_header_subcontainer {
        width: 50%;
    }

}
