.pc_home_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pc_home_logo {
    margin-bottom: 50px;
}

.pc_home_title {
    color: #fff;
    margin: 0%;
    font-size: 40px;
    margin-bottom: 4px;
    text-align: center;
    font-family: 'SF_ProDisplay_Heavy';
}

.pc_home_txt {
    color: #fff;
    text-align: center;
    margin: 0%;
    font-size: 22px;
    margin-left: 30px;
    margin-right: 30px;
    line-height: 20px;
    font-family: 'SF_ProDisplay_SemiBold';
}

.pc_home_button {
    margin-top: 45px;
    margin-bottom: 20px;
}

.pc_home_txt_span {
    color: #fff;
    margin: 0%;
    font-size: 14px;
    text-align: center;
    font-family: 'SF_ProDisplay_SemiBold';
}

@media (min-width: 760px) { 

    .pc_home_button {
        width: 70% !important;
    }

}
