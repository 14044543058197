.pc_selectStreamingService_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pc_selectStreamingService_listItems {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;
}
