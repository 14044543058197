.pc_yourMood_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pc_yourMood_listItems {
    margin-top: 35px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
