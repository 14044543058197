.pc_moviesResultContainer {
    flex: 1;
    height: 100svh;
    padding-top: 100px;
}

.pc_moviesResultContainer_loadingContainer {
    flex: 1;
    height: 100svh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
}
.pc_moviesResultContainer_loadingContainer_text {
    color: #fff;
    text-align: center;
    font-size: 30px;
    font-family: 'SF_ProDisplay_Heavy';
    line-height: 27px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

.pc_movie_list_container {
    position: absolute;
    bottom: 0%;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    height: 84svh;
    width: 100%;
    overflow-y: scroll;
}

.pc_movie_list_containerSection {
    display: flex;
    flex-direction: column;
}

.pc_movie_item_container {
    position: relative;
    min-height: 130px;
    border-radius: 15px;
    margin-bottom: 15px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    overflow: hidden;
}

.pc_movie_item_subcontainer {
    background-color: #00000050;
    width: 100%;
    height: 100%;
}
.pc_movie_item_bottomShadow {
    width: 100%;
    height: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 80%);
}
.pc_movie_item_bottomShadowActive {
    background: linear-gradient(0deg, rgba(0,0,0,1) 40%, rgba(255,255,255,0) 100%);
}

.pc_pc_movie_item_content_title_section {
    display: flex;
    align-items: end;
    margin-bottom: 14px;
}
.pc_pc_movie_item_content_Container {
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
}
.pc_movie_item_offer_icon {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    object-fit: cover;
}

.pc_movie_item_content_title {
    margin: 0%;
    color: #fff;
    font-size: 20px;
    font-family: 'SF_ProDisplay_Heavy';
}

.pc_movie_item_content_date {
    margin: 0%;
    color: #fff;
    font-size: 10px;
    margin-left: 3px;
    font-family: 'SF_ProDisplay_Medium';
}

.pc_pc_movie_item_content_sectionContainer {
    display: flex;
    align-items: center;
}
.pc_space_arround {
    justify-content: space-around;
    margin-top: 20px;
}
.pc_streamOrRent_container {
    margin-right: 5px;
}
.pc_streamOrRent_txt {
    margin: 0%;
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    font-family: 'SF_ProDisplay_Heavy';
    text-align: center;
}

.pc_pc_movie_item_content_footer_sectionContainer {
    margin-top: 20px;
}
.pc_pc_movie_item_content_footerHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
}
.pc_imdb_rate_container {
    display: flex;
    align-items: center;
}
.pc_genres_container {
    display: flex;
    align-items: center;
}
.pc_imdb_rate {
    margin: 0%;
    margin-left: 5px;
    color: #fff;
    font-size: 15px;
    font-family: 'SF_ProDisplay_Heavy';
}
/* .pc_imdb_rate span {
    font-size: 10px;
    font-family: 'SF_ProDisplay_Medium';
} */

.pc_imdb_description {
    margin: 0%;
    color: #fff;
    font-size: 14px;
    text-align: justify;
    font-family: 'SF_ProDisplay_Medium';
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 8px;
    margin-top: 15px;
}

.pc_genre_txt {
    margin: 0%;
    color: #fff;
    margin-left: 2px;
    margin-right: 2px;
    font-size: 13px;
    font-family: 'SF_ProDisplay_Medium';
}

.info_icon {
    position: absolute;
    top: 10px;
    right: 15px;
}

.pc_movieResult_actionsButtons_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.pc_movieResult_moreButton_container {
    margin-top: 30px;
    margin-bottom: 5px;
}
.pc_movieResult_moreButton {
    height: 50px;
    width: 80%;
}
.pc_movieResult_newSearchButton {
    width: 80%;
    height: 50px;
    font-size: 18px;
    font-family: 'SF_ProDisplay_Bold';
    margin-bottom: 20px;
}

@media (min-width: 760px) { 

    .pc_movieResult_actionsButtons_container {
        width: 30%;
        margin-left: 35%;
    }

    .pc_movie_list_containerSection {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: flex-start;
    }

    .pc_movie_item_container {
        width: 45%;
    }

    .pc_moviesResultContainer_loadingContainer_text {
        margin-left: 220px;
        margin-right: 220px;
    }
    
}
