.pc_kindOfCinema_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

.pc_kindOfCinema_listItems {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
