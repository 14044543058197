.pc_gradianButton_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pc_gradianButton_button {
    width: 90%;
    height: 60px;
    border-radius: 60px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
    font-family: 'SF_ProDisplay_Bold';
    background: rgb(139,222,218);
    background: linear-gradient(90deg, rgba(139,222,218,1) 0%, rgba(67,173,208,1) 25%, rgba(153,142,224,1) 50%, rgba(225,125,194,1) 75%, rgba(239,147,147,1) 100%);
}

.pc_gradianButton_button:hover {
    background: rgba(139, 222, 218, 0.70);
    background: linear-gradient(90deg, rgba(139, 222, 218, 0.70) 0%, rgba(67, 173, 208, 0.70) 25%, rgba(153, 142, 224, 0.70) 50%, rgba(225, 125, 193, 0.70) 75%, rgba(239, 147, 147, 0.70) 100%);
}