.pc_button_container {
    margin: 4px;
    height: 40px;
    font-size: 16px;
    background-color: transparent;
    border-color: #fff;
    color: #fff;
    outline: none;
    font-family: 'SF_ProDisplay_Regular';
}

.pc_noBorder {
    font-family: 'SF_ProDisplay_Medium';
}