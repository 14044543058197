.pc_select_container {
   width: 70%;
   border-radius: 10px;
}

.pc_selectCountrySectionComponent {
    display: flex;
    align-items: center;
}

.pc_selectedCountry_span_txt {
    padding: 10px;
}